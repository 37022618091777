










































import { Component, Vue } from 'vue-property-decorator';

import { WorkPlatformService } from '@WorkPlatform/api';

@Component({
  name: 'homeLatelyApp',
  components: {
    AppItem: () => import('./app-item.vue'),
    searchBar: () => import('../search-bar.vue'),
  },
})
export default class homeLatelyApp extends Vue {
  searchTxt: any = '';

  timer: any = '';

  list: any[] = [];

  loading: boolean = false;

  get total () {
    return this.list.length;
  }

  /**
   * 获取应用列表
   */
  async getAppList () {
    const params: any = {
      keyword: this.searchTxt,
      page: 1,
      size: 99,
    };

    const res: any = await WorkPlatformService.getAppList(params);

    if (res.success) {
      this.list = res.data.filter(item => {
        return item.homepages && item.homepages.mobileHomepage;
      });
    } else {
      this.list = [];
    }
    this.loading = true;
  }

  /**
   * 搜索：防抖
   * 删除搜索条件后不进行搜索并清空应用列表
   */
  async searchChange (e, r) {
    console.log(e, r);
    this.loading = false;
    clearTimeout(this.timer);
    const wait: number = 800;
    this.timer = setTimeout(async () => {
      if (this.searchTxt) {
        this.getAppList();
      } else {
        this.list = [];
      }
    }, wait);
  }

  cancel () {
    this.$router.go(-1);
  }
}
